[data-v-1de64900] .el-button + .el-button {
  margin-left: 10px;
}
.el-card__body[data-v-1de64900] {
  padding: 0px !important;
}

/* .avue-crud__pagination {
  .el-card__body {
    margin: 0 !important;
    padding: 1px 25px 0px 25px !important;
    width: 100% !important;
    height: auto !important;
    background: #f3f3f3 !important;
    box-sizing: border-box;
    .el-pagination {
      width: 100%;
      line-height: 32px;
      .el-pagination__total,
      .el-pagination__sizes {
        float: left;
      }
    }
  }
} */
.avue-form[data-v-1de64900] {
  height: 44px;
}
.avue-crud__pagination[data-v-1de64900] {
  background-color: #ffffff !important;
}
.el-link[data-v-1de64900] {
  color: #1b1b1b;
  text-decoration: underline;
  -webkit-text-decoration-color: #1b1b1b;
          text-decoration-color: #1b1b1b;
}
.el-link[data-v-1de64900]:hover {
  color: #6c757d;
  text-decoration: none;
}